(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// Fonction pour encoder une chaîne en Base64
function encodeBase64(str) {
  return btoa(str);
}

// Fonction pour obfusquer les liens dans un élément donné
function obfuscateLinksInFooter() {
  // Sélectionner le div avec l'ID "footer"
  var footer = document.getElementById('footer');
  if (!footer) return;

  // Récupérer tous les liens dans le div
  var links = footer.getElementsByTagName('a');

  // Parcourir chaque lien et obfusquer son href
  var _iterator = _createForOfIteratorHelper(links),
    _step;
  try {
    var _loop = function _loop() {
      var link = _step.value;
      var originalHref = link.getAttribute('href');
      if (originalHref) {
        // Encoder l'URL en Base64
        var obfuscatedHref = encodeBase64(originalHref);

        // Remplacer l'attribut href par l'URL obfusquée
        link.setAttribute('data-obfuscated-href', obfuscatedHref);
        link.removeAttribute('href'); // Supprimer l'attribut href pour empêcher le clic direct

        // Ajouter un événement pour restaurer l'URL au clic
        link.addEventListener('click', function (event) {
          event.preventDefault(); // Empêcher le comportement par défaut
          var decodedHref = atob(obfuscatedHref); // Décoder l'URL
          window.location.href = decodedHref; // Rediriger vers l'URL décodée
        });
      }
    };
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}
function navbarAnimate(el) {
  el.animate([
  // keyframes
  /*{ height: 0, 'opacity': 0 }, 
  { height: '80vh', 'opacity': 1 }*/
  {
    'opacity': 0
  }, {
    'opacity': 1
  }], {
    // timing options
    duration: 800,
    easing: 'ease-out',
    iterations: 1
  });
}
jQuery(document).ready(function ($) {
  // Handler for .ready() called.
  $('.mh').matchHeight();
});
document.addEventListener("DOMContentLoaded", function () {
  obfuscateLinksInFooter();
  faq = document.getElementById('accordion-faq');
  console.log(faq);
  if (faq) {
    document.querySelectorAll('.tab-label').forEach(function (label) {
      label.addEventListener('click', function () {
        var input = document.getElementById(this.getAttribute('for'));
        if (input) {
          input.checked = true;
        }
      });
    });
  }
  function initializeSlick() {
    if (window.innerWidth <= 768) {
      jQuery('#slider-projects').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000
      });
    } else if (jQuery('#slider-projects').hasClass('slick-initialized')) {
      jQuery('#slider-projects').slick('unslick');
    }
  }
  if (document.getElementById('#slider-projects')) {
    initializeSlick();
    window.addEventListener('resize', initializeSlick);
  }

  /********************************************** */
  /***  make menu dropdown hover work on mobile ***/
  /************************************************/
  document.querySelectorAll('.dropdown').forEach(function (dropdown) {
    dropdown.addEventListener('touchstart', function (event) {
      // Check if the clicked element is a link in a nested dropdown
      if (event.target.matches('.dropdown .dropdown .dropdown-menu a')) {
        // Do nothing, let the default link behavior occur
        console.log('ok');
        return;
      }

      // Prevent the event from propagating up to parent elements
      event.stopPropagation();

      // Find the direct child .dropdown-menu of the clicked .dropdown
      var dropdownMenu = dropdown.querySelector('.dropdown-menu');

      // Toggle display of the dropdownMenu
      toggleDisplay(dropdownMenu);
    });
  });
  function toggleDisplay(element) {
    if (!element) return; // Check if the element exists

    if (element.style.display === "none" || element.style.display === "") {
      element.style.display = "block";
      element.style.opacity = 1;
    } else {
      element.style.display = "none";
      element.style.opacity = 0;
    }
  }

  // Get all .menu-item-has-children elements
  var menuItems = document.querySelectorAll('.menu-item-has-children');

  /* menuItems.forEach(function(menuItem) {
  	let dropdown = menuItem.querySelector('.dropdown-menu');
  	if(dropdown) {
  	 // When hovering over the <a> inside .menu-item-has-children
  	 menuItem.querySelector('a').addEventListener('mouseover', function() {
  			 var dropdown = menuItem.querySelector('.dropdown-menu');
  			 if (dropdown) {
  					 dropdown.style.display = 'block';
  			 }
  	 });
  			 // When the mouse leaves the .menu-item-has-children container
  	 menuItem.addEventListener('mouseleave', function() {
  			 var dropdown = menuItem.querySelector('.dropdown-menu');
  			 if (dropdown) {
  					 dropdown.style.display = 'none';
  			 }
  	 });
  	}
  }); */

  /* let mainMenu = document.querySelector('.menu-main');
  let contactHtml = '<li class="menu-btn menu-item nav-item"><div>';
  contactHtml += '<div><a class="nav-link contact" data-layer="clickform" id="contact"  href="mailto:contact@ilestunefois.com">DEMANDER UN DEVIS</a></div>';
  contactHtml += "</div></li>";
  mainMenu.insertAdjacentHTML('beforeend', contactHtml);   */

  var toggler = document.querySelector('.navbar-toggler');
  var collapse = document.getElementById('navbarNav');
  toggler.addEventListener('click', function (event) {
    if (collapse.classList.contains('show')) {
      collapse.classList.remove('show');
      document.getElementById('header-container').classList.remove('open');
    } else {
      collapse.classList.add('show');
      document.getElementById('header-container').classList.add('open');
      navbarAnimate(collapse);
    }
  });
  if (jQuery('.testimonial-slider')) {
    jQuery('.testimonial-slider').on('init', function (event, slick) {
      jQuery(this).css({
        'opacity': '1',
        'visibility': 'visible'
      });
    });
    jQuery('.video-slide').on('click', function () {
      var videoID = jQuery(this).data('video-id');
      var embedCode = '<div></div><div class="embed-responsive embed-responsive-16by9"><iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoID + '?autoplay=1" frameborder="0" allowfullscreen></iframe></div></div>';
      jQuery(this).replaceWith(embedCode);
    });
    jQuery('.testimonial-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          appendArrows: jQuery('.left-2')
        }
      }, {
        breakpoint: 1919,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          appendArrows: jQuery('.left-2')
        }
      }],
      cssEase: 'ease-out',
      useTransform: true
    });
  }
  jQuery('#slider-projects').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        appendArrows: jQuery('.left-2')
      }
    }, {
      breakpoint: 1919,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: jQuery('.left-2')
      }
    }],
    cssEase: 'ease-out',
    useTransform: true
  });
  jQuery('.poster-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    cssEase: 'ease-out'
  });
  jQuery('.slider-logos').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    mobileFirst: true,
    useTransform: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
        //appendArrows: jQuery('.hideonmobile')
      }
    }],
    cssEase: 'ease-out'
    //appendArrows: jQuery('.hideondesktop')
  });

  /*********************************************************
  		VIDEO SYSTEM PORTFOLIO
  		***************************************************/
  if (document.querySelectorAll(".poster")) {
    var poster = document.querySelectorAll(".poster");
    poster.forEach(function (elem) {
      elem.addEventListener("mouseover", function (event) {
        var video = event.target;
        if (video.paused) {
          video.play();
        }
      }, false);
      elem.addEventListener("mouseout", function (event) {
        var video = event.target;

        //video.load();
        video.currentTime = 0;
        video.pause();
        //alert(video.currentTime);
      }, false);
      elem.addEventListener("click", function (event) {
        players.forEach(function (el) {
          el.stopVideo();
        });
        poster.forEach(function (el) {
          el.style.display = "block";
        });
        var video = event.target;
        elem.style.display = 'none';
        var position = elem.dataset.position;
        players[position].playVideo();
        event.preventDefault();
      }, false);
    });
  }

  /************************************************
           MODAL
  		******************************************/

  var cta_btn = document.querySelectorAll('.cta-btn');

  /* 	let connect_mobile = document.querySelector('.connect-mobile'); */

  /* 	let connect_btn = document.querySelector('.menu-btn');
  		connect_btn.addEventListener('click' , function(event) {
  
  		console.log("ok");
  
  		event.preventDefault();
  		
  		let modal = new Modal();
  		modal.load_template();
  		modal.animate();
  		modal.set_overlay(true);
  		modal.close_btn.addEventListener('click', modal.close);
  	});  */

  /* 	cta_btn.forEach(function (elem) {
  	
  	elem.addEventListener("click", function(event) {
  
  			event.preventDefault();
  			
  			let modal = new Modal();
  			modal.load_template();
  			modal.animate();
  			modal.set_overlay(true);
  			modal.close_btn.addEventListener('click', modal.close);
  		}) 
  	
  	}); */

  /* connect_mobile.addEventListener('touchstart' , function(event) {
  
  
  	event.preventDefault();
  	
  	let modal = new Modal();
  	modal.load_template();
  	modal.animate_mobile();
  	modal.set_overlay(true);
  	modal.close_btn.addEventListener('click', modal.close);
  });  */

  // When the user scrolls the page, execute myFunction
  window.onscroll = function () {
    makeSticky();
  };

  // Get the header
  var navbar = document.getElementById("navbarNav-container");
  var bg = document.querySelector(".bg-sticky");

  // Get the offset position of the navbar
  var sticky = navbar.offsetTop;

  // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function makeSticky() {
    if (window.pageYOffset > sticky) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  }
});
jQuery(window).bind('scroll', function () {
  if (jQuery(this).scrollTop() > 200) {
    jQuery('.page-template-chooseus .sub_header').addClass('grow');
  } else {
    jQuery('.page-template-chooseus .sub_header').removeClass('grow');
  }
});

},{}]},{},[1]);
